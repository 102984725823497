import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { initialValues, validationSchema } from "./schema/schema";

const AddStandard = () => {
  const { search } = useLocation();

  const { get: getChapter, post } = useFetch(
    `/api/admin/chapter/${parse(search).id}`
  );
  const { get, response } = useFetch("/api/admin/board");
  const { get: getStandard, response: standardResponse } = useFetch(
    "/api/admin/standard"
  );
  const { get: getSubject, response: subjectResponse } =
    useFetch("/api/admin/subject");

  const { get: getChapterGroup, response: chapterGroupResponse } = useFetch(
    "/api/admin/chapter-group"
  );

  const [initState, setInitState] = useState(initialValues);

  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    (async () => {
      await Promise.all([
        get(),
        getStandard(),
        getSubject(),
        getChapterGroup(),
      ]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isNil(search)) {
        const { data } = await getChapter();

        setInitState({
          ...data,
          board: data.board._id,
          subject: data.subject._id,
          standard: data.standard._id,
        });
      }
    })();
  }, [search]);

  const { push } = useHistory();

  const handlePdfChange = (e) => {
    try {
      const file = e.target.files[0];

      if (file) {
        setPdf(file);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { uploadImg } = useFileUpload();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);

    let pdfName = "";

    if (pdf !== null) {
      const pdfString = await uploadImg(pdf);
      pdfName = pdfString;
    } else {
      pdfName = val.pdf;
    }

    const { success, data, msg } = await post({ ...val, pdf: pdfName });

    if (success) {
      setSubmitting(false);
      push("/chapters");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="boards" pageContent="add Chapter" isBack={true}>
      <Formik
        initialValues={initState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="select board"
                    name="board"
                    onChange={() => {
                      setFieldValue("standard", "");
                      setFieldValue("subject", "");
                    }}
                    value={values.board}
                    isInvalid={!!touched.board && !!errors.board}
                  >
                    {isArray(response?.data?.data) &&
                      response.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="select standard"
                    name="standard"
                    onChange={() => {
                      setFieldValue("subject", "");
                    }}
                    value={values.standard}
                    isInvalid={!!touched.standard && !!errors.standard}
                  >
                    {isArray(standardResponse?.data?.data) &&
                      standardResponse.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => x.board._id === values.board)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="select subject"
                    name="subject"
                    value={values.subject}
                    isInvalid={!!touched.subject && !!errors.subject}
                  >
                    {isArray(subjectResponse?.data?.data) &&
                      subjectResponse.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => x.standard._id === values.standard)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="select subject"
                    name="chapterGroup"
                    value={values.chapterGroup}
                    isInvalid={!!touched.chapterGroup && !!errors.chapterGroup}
                  >
                    {isArray(chapterGroupResponse?.data?.data) &&
                      chapterGroupResponse.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => x.subject._id === values.subject)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="Chapter Name"
                    placeholder="Enter Chapter Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>
                      <strong>
                        Chapter pdf
                        {"   "}
                        {initState.pdf && (
                          <a
                            className="badge badge-primary"
                            href={`${process.env.REACT_APP_API_URL}/api/upload/file/${initState.pdf}`}
                            target="_blank"
                          >
                            old pdf
                          </a>
                        )}
                      </strong>
                    </Form.Label>
                    <Form.File
                      accept="application/pdf"
                      name="pdf"
                      isInvalid={!!touched.pdf && !!errors.pdf}
                      onChange={handlePdfChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="Chapter video link"
                    placeholder="Enter video link"
                    name="video"
                    value={values.video}
                    isInvalid={!!touched.video && !!errors.video}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="show order"
                    placeholder="enter chapter show order"
                    name="order"
                    value={values.order}
                    isInvalid={!!touched.order && !!errors.order}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="update" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </WrapLayout>
  );
};

export default AddStandard;
