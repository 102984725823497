import * as Yup from "yup";

const types = ["video", "image"];
var regExp =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

const initialValues = {
  bannerType: "",
  tag: "",
  title: "",
  description: "",
  img: "",
  buttonTitle: "",
  buttonLink: "",
  isActive: true,
  video: "",
};

const validationSchema = Yup.object().shape({
  bannerType: Yup.string().oneOf(types).required(),
  tag: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string(),
  img: Yup.string(),
  buttonTitle: Yup.string(),
  buttonLink: Yup.string(),
  isActive: Yup.bool().oneOf([true, false]).required(),
  video: Yup.string().matches(regExp),
});

export { initialValues, validationSchema };
