import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useForceUpdate from "use-force-update";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import { ManageLayout } from "../../components/Manage/ManageLayout";
import DataTable, { TableSvg } from "../../components/Table/DataTable";
import AddCenter from "./add";
import UpdateCenter from "./update";

const MenuDropDown = ({ e, handleEdit, reFetch, cache, forceUpdate }) => {
  const { del } = useFetch(`/api/admin/state/${e.row.original._id}`);

  const handleDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this document!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await del().then(async () => {
          await cache.clear();
          await reFetch();
          forceUpdate();
        });
      }
    });
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="success" className="light sharp i-false">
          {TableSvg}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleEdit(e.row.original._id)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const ShowCenter = () => {
  const { get, response, loading, cache } = useFetch("/api/admin/state");
  const forceUpdate = useForceUpdate();

  const { push } = useHistory();

  useEffect(() => get(), []);

  const handleEdit = (itx) => () => {
    push(`?action=update&id=${itx}`);
  };

  const columnInfo = [
    {
      Header: "No",
      accessor: "id",
    },
    {
      Header: "country",
      accessor: "country.name",
    },
    {
      Header: "name",
      accessor: "name",
    },
    {
      Header: "status",
      accessor: "isActive",
      Cell: (e) => {
        if (e.value) {
          return <span className="badge badge-success">active</span>;
        } else {
          return <span className="badge badge-danger">inactive</span>;
        }
      },
    },
    {
      Header: "action",
      Cell: (e) => {
        return (
          <MenuDropDown
            forceUpdate={forceUpdate}
            e={e}
            handleEdit={handleEdit}
            reFetch={get}
            cache={cache}
          />
        );
      },
    },
  ];

  return (
    <WrapLayout motherMenu="parameters" pageContent="State" shouldCard={false}>
      <DataTable
        tableTitle="State"
        bText="Add state"
        isLoading={loading}
        columnData={response.data?.data}
        columnInfo={columnInfo}
      />
    </WrapLayout>
  );
};

const Index = () => {
  return (
    <ManageLayout Index={ShowCenter} Add={AddCenter} Update={UpdateCenter}>
      index
    </ManageLayout>
  );
};

export default Index;
