import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";
import { initialValues, validationSchema } from "./schema/schema";

const AddTestimonial = () => {
  const { post } = useFetch("/api/admin/faculty");

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  const { uploadImg } = useFileUpload();

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);

    if (isNil(cropedImg)) {
      cogoToast.error("Please upload the respective image.");
      return;
    }

    const img = await uploadImg(cropedImg);

    const { success, data, msg } = await post({ ...val, img: img });

    if (success) {
      setSubmitting(false);
      push("/faculty");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="pages" pageContent="add faculty" isBack={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomInput
                    title="faculty Name"
                    placeholder="Enter faculty Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label> Faculty Image</Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="faculty instagram"
                    placeholder="Enter instagram Link"
                    name="instagram"
                    value={values.instagram}
                    isInvalid={!!touched.instagram && !!errors.instagram}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="faculty email address"
                    placeholder="Enter email address"
                    name="mail"
                    value={values.mail}
                    isInvalid={!!touched.mail && !!errors.mail}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="faculty phone number"
                    placeholder="Enter phone number"
                    name="phone"
                    value={values.phone}
                    isInvalid={!!touched.phone && !!errors.phone}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
        aspect={1}
      />
    </WrapLayout>
  );
};

export default AddTestimonial;
