import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";

import { initialValues, validationSchema } from "./schema/schema";

const UpdateBanner = () => {
  const { search } = useLocation();

  const { get, post } = useFetch(`/api/admin/banner/${parse(search).id}`);
  const { push } = useHistory();

  const { uploadImg } = useFileUpload();

  const [initState, setInitState] = useState(initialValues);

  useEffect(() => {
    (async () => {
      if (!isNil(search)) {
        const { data } = await get();

        setInitState({ ...data });
      }
    })();
  }, [search]);

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    let imgName = "";

    if (!isNil(cropedImg)) {
      imgName = await uploadImg(cropedImg);
      // cogoToast.error("Please upload the respective image.");
      // return;
    } else {
      imgName = val.img;
    }

    const { success, data, msg } = await post({ ...val, img: imgName });

    if (success) {
      push("/banners");
    } else {
      cogoToast.error("something went wrong on server");
    }

    setSubmitting(false);
  };

  return (
    <WrapLayout motherMenu="manage" pageContent="update banner" isBack={true}>
      <Formik
        initialValues={initState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="banner Type"
                    placeholder="Enter banner Type"
                    name="bannerType"
                    value={values.bannerType}
                    isInvalid={!!touched.bannerType && !!errors.bannerType}
                  >
                    <option value={"video"}>Video</option>
                    <option value={"image"}>Image</option>
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="banner Tag"
                    placeholder="Enter banner Tag"
                    name="tag"
                    value={values.tag}
                    isInvalid={!!touched.tag && !!errors.tag}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="banner Title"
                    placeholder="Enter banner Title"
                    name="title"
                    value={values.title}
                    isInvalid={!!touched.title && !!errors.title}
                  />
                </Col>
                <Col md="12">
                  <CustomInput
                    title="banner description"
                    placeholder="Enter banner description"
                    name="description"
                    value={values.description}
                    as="textarea"
                    isInvalid={!!touched.description && !!errors.description}
                  />
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>
                      Banner Image
                      {"  "}{" "}
                      <a
                        className="badge badge-primary"
                        href={`${process.env.REACT_APP_API_URL}/api/upload/${initState.img}`}
                        target="_blank"
                      >
                        old image
                      </a>
                    </Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="banner button title"
                    placeholder="Enter banner Button title"
                    name="buttonTitle"
                    value={values.buttonTitle}
                    isInvalid={!!touched.buttonTitle && !!errors.buttonTitle}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="banner button link"
                    placeholder="Enter banner Button link"
                    name="buttonLink"
                    value={values.buttonLink}
                    isInvalid={!!touched.buttonLink && !!errors.buttonLink}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="banner youtube link"
                    placeholder="Enter banner youtube link"
                    name="video"
                    value={values.video}
                    isInvalid={!!touched.video && !!errors.video}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="update" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
        aspect={16 / 8.08}
      />
    </WrapLayout>
  );
};

export default UpdateBanner;
