import * as Yup from "yup";

const initialValues = {
  label: "",
  title: "",
  content: "<p>This is the initial content of the editor</p>",
  showLocation: "about us",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  label: Yup.string().required(),
  title: Yup.string().required(),
  content: Yup.string().required(),
  showLocation: Yup.string().required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
