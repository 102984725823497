import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isArray from "lodash/isArray";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import { CustomEditor } from "../../components/Form/Editor";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";

import { initialValues, validationSchema } from "./schema/schema";

const AddState = () => {
  const { post } = useFetch("/api/admin/course-info");

  const { get, response } = useFetch("/api/admin/course");

  const { get: getCategory, response: resCategory } = useFetch(
    "/api/admin/course-category"
  );

  const { get: getExamLevel, response: getExamLevelResponse } = useFetch(
    "/api/admin/exam-level"
  );

  const { get: getExamMode, response: getExamModeResponse } = useFetch(
    "/api/admin/exam-mode"
  );

  const { get: getExamType, response: getExamTypeResponse } = useFetch(
    "/api/admin/exam-fee-type"
  );

  useEffect(() => {
    (async () => {
      await Promise.all([
        get(),
        getCategory(),
        getExamLevel(),
        getExamMode(),
        getExamType(),
      ]);
    })();
  }, []);

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    const { success, data, msg } = await post(val);

    if (success) {
      setSubmitting(false);
      push("/course-info");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="boards" pageContent="Add course info" isBack={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="Select course"
                    placeholder="Enter Country Name"
                    name="course"
                    value={values.course}
                    isInvalid={!!touched.course && !!errors.course}
                  >
                    {isArray(response?.data?.data) &&
                      response.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="course Category"
                    placeholder="Enter Country Name"
                    name="courseCategory"
                    value={values.courseCategory}
                    isInvalid={
                      !!touched.courseCategory && !!errors.courseCategory
                    }
                  >
                    {isArray(resCategory?.data?.data) &&
                      resCategory.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => {
                          if (x.course._id === values.course) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="video"
                    placeholder="Enter video url"
                    name="video"
                    value={values.video}
                    isInvalid={!!touched.video && !!errors.video}
                  />
                </Col>
                <Col md="4" className="d-none">
                  <CustomInput
                    title="Exam Name"
                    placeholder="Enter Exam Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <Form.Label>Exam overview</Form.Label>
                    <CustomEditor
                      name="overview"
                      setVal={setFieldValue}
                      initialValues={values.overview}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="Exam Level"
                    placeholder="Enter Exam Level"
                    name="newLevel"
                    value={values.newLevel}
                    isInvalid={!!touched.newLevel && !!errors.newLevel}
                  >
                    {isArray(getExamLevelResponse?.data?.data) &&
                      getExamLevelResponse.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="eligibility"
                    placeholder="Enter eligibility"
                    name="eligibility"
                    value={values.eligibility}
                    isInvalid={!!touched.eligibility && !!errors.eligibility}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="preparation Period"
                    placeholder="Enter preparation Period"
                    name="preparationPeriod"
                    value={values.preparationPeriod}
                    isInvalid={
                      !!touched.preparationPeriod && !!errors.preparationPeriod
                    }
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="exam Duration"
                    placeholder="Enter exam Duration"
                    name="examDuration"
                    value={values.examDuration}
                    isInvalid={!!touched.examDuration && !!errors.examDuration}
                  />
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="Select Exam mode"
                    placeholder="Enter Exam Level"
                    name="newMode"
                    value={values.newMode}
                    isInvalid={!!touched.newMode && !!errors.newMode}
                  >
                    {isArray(getExamModeResponse?.data?.data) &&
                      getExamModeResponse.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="exam Fees"
                    placeholder="Enter exam Fees"
                    name="totalFee"
                    value={values.totalFee}
                    isInvalid={!!touched.totalFee && !!errors.totalFee}
                  />
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="Exan Type"
                    placeholder="Enter Exam Type"
                    name="examType"
                    value={values.examType}
                    isInvalid={!!touched.examType && !!errors.examType}
                  >
                    {isArray(getExamTypeResponse?.data?.data) &&
                      getExamTypeResponse.data.data.map((x) => {
                        return (
                          <option value={x._id}>
                            {x.name} - {x.duration} (month)
                          </option>
                        );
                      })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="exam frequency"
                    placeholder="Enter exam frequency"
                    name="frequency"
                    value={values.frequency}
                    isInvalid={!!touched.frequency && !!errors.frequency}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="Passing Criteria"
                    placeholder="enter Passing Criteria"
                    name="upPassingCriteria"
                    value={values.upPassingCriteria}
                    isInvalid={
                      !!touched.upPassingCriteria && !!errors.upPassingCriteria
                    }
                  />
                </Col>
                <Col md="4" className="d-none">
                  <CustomInput
                    title="Exam min passing percentage"
                    placeholder="Enter Exam min passing percentage"
                    name="minPercentage"
                    value={values.minPercentage}
                    isInvalid={
                      !!touched.minPercentage && !!errors.minPercentage
                    }
                  />
                </Col>
                <Col md="4" className="d-none">
                  <CustomInput
                    title="Exam max passing percentage"
                    placeholder="Enter Exam max passing percentage"
                    name="maxPercentage"
                    value={values.maxPercentage}
                    isInvalid={
                      !!touched.maxPercentage && !!errors.maxPercentage
                    }
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </WrapLayout>
  );
};

export default AddState;



// import cogoToast from "cogo-toast";
// import { Formik } from "formik";
// import isArray from "lodash/isArray";
// import React, { useEffect } from "react";
// import { Col, Form, Row } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
// import useFetch from "use-http";
// import WrapLayout from "../../components/Form/common/WrapLayout";
// import { CustomEditor } from "../../components/Form/Editor";
// import {
//   CustomButton,
//   CustomCheck,
//   CustomInput,
//   CustomSelect,
// } from "../../components/Form/input";

// import { initialValues, validationSchema } from "./schema/schema";

// const AddState = () => {
//   const { post } = useFetch("/api/admin/course-info");

//   const { get, response } = useFetch("/api/admin/course");

//   const { get: getCategory, response: resCategory } = useFetch(
//     "/api/admin/course-category"
//   );

//   const { get: getExamLevel, response: getExamLevelResponse } = useFetch(
//     "/api/admin/exam-level"
//   );

//   const { get: getExamMode, response: getExamModeResponse } = useFetch(
//     "/api/admin/exam-mode"
//   );

//   const { get: getExamType, response: getExamTypeResponse } = useFetch(
//     "/api/admin/exam-fee-type"
//   );

//   useEffect(() => {
//     (async () => {
//       await Promise.all([
//         get(),
//         getCategory(),
//         getExamLevel(),
//         getExamMode(),
//         getExamType(),
//       ]);
//     })();
//   }, []);

//   const { push } = useHistory();

//   const handleSubmit = async (val, { setSubmitting }) => {
//     setSubmitting(true);
//     const { success, data, msg } = await post(val);

//     if (success) {
//       setSubmitting(false);
//       push("/course-info");
//     } else {
//       cogoToast.error("something went wrong on server");
//     }
//   };

//   return (
//     <WrapLayout motherMenu="boards" pageContent="Add course info" isBack={true}>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={handleSubmit}
//       >
//         {({
//           handleSubmit,
//           handleChange,
//           values,
//           touched,
//           errors,
//           setFieldValue,
//           isSubmitting,
//         }) => {
//           return (
//             <Form onSubmit={handleSubmit} onChange={handleChange}>
//               <Row>
//                 <Col md="4">
//                   <CustomSelect
//                     title="Select course"
//                     placeholder="Enter Country Name"
//                     name="course"
//                     value={values.course}
//                     isInvalid={!!touched.course && !!errors.course}
//                   >
//                     {isArray(response?.data?.data) &&
//                       response.data.data
//                         .filter((x) => x.isActive === true)
//                         .map((x) => {
//                           return (
//                             <option value={x._id} key={x._id}>
//                               {x.name}
//                             </option>
//                           );
//                         })}
//                   </CustomSelect>
//                 </Col>
//                 <Col md="4">
//                   <CustomSelect
//                     title="course Category"
//                     placeholder="Enter Country Name"
//                     name="courseCategory"
//                     value={values.courseCategory}
//                     isInvalid={
//                       !!touched.courseCategory && !!errors.courseCategory
//                     }
//                   >
//                     {isArray(resCategory?.data?.data) &&
//                       resCategory.data.data
//                         .filter((x) => x.isActive === true)
//                         .filter((x) => {
//                           if (x.course._id === values.course) {
//                             return true;
//                           } else {
//                             return false;
//                           }
//                         })
//                         .map((x) => {
//                           return (
//                             <option value={x._id} key={x._id}>
//                               {x.name}
//                             </option>
//                           );
//                         })}
//                   </CustomSelect>
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="video"
//                     placeholder="Enter video url"
//                     name="video"
//                     value={values.video}
//                     isInvalid={!!touched.video && !!errors.video}
//                   />
//                 </Col>
//                 <Col md="4" className="d-none">
//                   <CustomInput
//                     title="Exam Name"
//                     placeholder="Enter Exam Name"
//                     name="name"
//                     value={values.name}
//                     isInvalid={!!touched.name && !!errors.name}
//                   />
//                 </Col>
//                 <Col md="12" className="mb-3">
//                   <Form.Group>
//                     <Form.Label>Exam overview</Form.Label>
//                     <CustomEditor
//                       name="overview"
//                       setVal={setFieldValue}
//                       initialValues={values.overview}
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col md="4">
//                   <CustomSelect
//                     title="Exam Level"
//                     placeholder="Enter Exam Level"
//                     name="newLevel"
//                     value={values.newLevel}
//                     isInvalid={!!touched.newLevel && !!errors.newLevel}
//                   >
//                     {isArray(getExamLevelResponse?.data?.data) &&
//                       getExamLevelResponse.data.data
//                         .filter((x) => x.isActive === true)
//                         .map((x) => {
//                           return (
//                             <option value={x._id} key={x._id}>
//                               {x.name}
//                             </option>
//                           );
//                         })}
//                   </CustomSelect>
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="eligibility"
//                     placeholder="Enter eligibility"
//                     name="eligibility"
//                     value={values.eligibility}
//                     isInvalid={!!touched.eligibility && !!errors.eligibility}
//                   />
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="preparation Period"
//                     placeholder="Enter preparation Period"
//                     name="preparationPeriod"
//                     value={values.preparationPeriod}
//                     isInvalid={
//                       !!touched.preparationPeriod && !!errors.preparationPeriod
//                     }
//                   />
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="exam Duration"
//                     placeholder="Enter exam Duration"
//                     name="examDuration"
//                     value={values.examDuration}
//                     isInvalid={!!touched.examDuration && !!errors.examDuration}
//                   />
//                 </Col>
//                 <Col md="4">
//                   <CustomSelect
//                     title="Select Exam mode"
//                     placeholder="Enter Exam Level"
//                     name="newMode"
//                     value={values.newMode}
//                     isInvalid={!!touched.newMode && !!errors.newMode}
//                   >
//                     {isArray(getExamModeResponse?.data?.data) &&
//                       getExamModeResponse.data.data
//                         .filter((x) => x.isActive === true)
//                         .map((x) => {
//                           return (
//                             <option value={x._id} key={x._id}>
//                               {x.name}
//                             </option>
//                           );
//                         })}
//                   </CustomSelect>
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="exam Fees"
//                     placeholder="Enter exam Fees"
//                     name="totalFee"
//                     value={values.totalFee}
//                     isInvalid={!!touched.totalFee && !!errors.totalFee}
//                   />
//                 </Col>
//                 <Col md="4">
//                   <CustomSelect
//                     title="Exan Type"
//                     placeholder="Enter Exam Type"
//                     name="examType"
//                     value={values.examType}
//                     isInvalid={!!touched.examType && !!errors.examType}
//                   >
//                     {isArray(getExamTypeResponse?.data?.data) &&
//                       getExamTypeResponse.data.data.map((x) => {
//                         return (
//                           <option value={x._id}>
//                             {x.name} - {x.duration} (month)
//                           </option>
//                         );
//                       })}
//                   </CustomSelect>
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="exam frequency"
//                     placeholder="Enter exam frequency"
//                     name="frequency"
//                     value={values.frequency}
//                     isInvalid={!!touched.frequency && !!errors.frequency}
//                   />
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="Exam min passing percentage"
//                     placeholder="Enter Exam min passing percentage"
//                     name="minPercentage"
//                     value={values.minPercentage}
//                     isInvalid={
//                       !!touched.minPercentage && !!errors.minPercentage
//                     }
//                   />
//                 </Col>
//                 <Col md="4">
//                   <CustomInput
//                     title="Exam max passing percentage"
//                     placeholder="Enter Exam max passing percentage"
//                     name="maxPercentage"
//                     value={values.maxPercentage}
//                     isInvalid={
//                       !!touched.maxPercentage && !!errors.maxPercentage
//                     }
//                   />
//                 </Col>
//                 <Col md="4">
//                   <CustomCheck
//                     title="mark as active"
//                     placeholder="Make Active"
//                     setVal={setFieldValue}
//                     checked={values.isActive}
//                     name="isActive"
//                     isInvalid={!!touched.isActive && !!errors.isActive}
//                   />
//                 </Col>
//                 <Col md="12"></Col>
//                 <Col md="4">
//                   <CustomButton bText="Add" isLoading={isSubmitting} />
//                 </Col>
//               </Row>
//             </Form>
//           );
//         }}
//       </Formik>
//     </WrapLayout>
//   );
// };

// export default AddState;
