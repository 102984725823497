import * as Yup from "yup";

var regExp =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

const initialValues = {
  course: "",
  courseCategory: "",
  name: "",
  level: "",

  eligibility: "",
  preparationPeriod: "",
  examDuration: "",
  examMode: "",
  totalFee: "",
  frequency: "",
  passingCriteria: "",
  overview: "<p>This is the initial content of the editor</p>",
  // curriculum: "",
  video: "",
  img: "",
  isActive: true,

  // new level

  newLevel: "",
  newMode: "",

  minPercentage: "",
  maxPercentage: "",

  upPassingCriteria: "",

  examType: "",
};

const validationSchema = Yup.object().shape({
  course: Yup.string().required(),
  courseCategory: Yup.string().required(),
  name: Yup.string(),
  level: Yup.string(),
  eligibility: Yup.string().required(),
  preparationPeriod: Yup.string().required(),
  examDuration: Yup.string().required(),
  examMode: Yup.string(),
  totalFee: Yup.number().min(1).required(),
  frequency: Yup.string(),
  // frequency: Yup.string().required(),
  passingCriteria: Yup.number().min(0).max(100),
  overview: Yup.string().required(),
  // curriculum: Yup.string().required(),
  video: Yup.string().matches(regExp),
  img: Yup.string(),
  isActive: Yup.bool().oneOf([true, false]).required(),

  newLevel: Yup.string().required(),
  newMode: Yup.string().required(),

  upPassingCriteria: Yup.string().required(),

  minPercentage: Yup.number().min(0).max(100),
  maxPercentage: Yup.number().min(0).max(100),

  examType: Yup.string(),
});

export { initialValues, validationSchema };



// import * as Yup from "yup";

// var regExp =
//   /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

// const initialValues = {
//   course: "",
//   courseCategory: "",
//   name: "",
//   level: "",

//   eligibility: "",
//   preparationPeriod: "",
//   examDuration: "",
//   examMode: "",
//   totalFee: "",
//   frequency: "",
//   passingCriteria: "",
//   overview: "<p>This is the initial content of the editor</p>",
//   // curriculum: "",
//   video: "",
//   img: "",
//   isActive: true,

//   // new level

//   newLevel: "",
//   newMode: "",

//   minPercentage: "",
//   maxPercentage: "",

//   examType: "",
// };

// const validationSchema = Yup.object().shape({
//   course: Yup.string().required(),
//   courseCategory: Yup.string().required(),
//   name: Yup.string(),
//   level: Yup.string(),
//   eligibility: Yup.string().required(),
//   preparationPeriod: Yup.string().required(),
//   examDuration: Yup.string().required(),
//   examMode: Yup.string(),
//   totalFee: Yup.number().min(1).required(),
//   frequency: Yup.string(),
//   // frequency: Yup.string().required(),
//   passingCriteria: Yup.number().min(0).max(100),
//   overview: Yup.string().required(),
//   // curriculum: Yup.string().required(),
//   video: Yup.string().matches(regExp),
//   img: Yup.string(),
//   isActive: Yup.bool().oneOf([true, false]).required(),

//   newLevel: Yup.string().required(),
//   newMode: Yup.string().required(),

//   minPercentage: Yup.number().min(0).max(100).required(),
//   maxPercentage: Yup.number().min(0).max(100).required(),

//   examType: Yup.string(),
// });

// export { initialValues, validationSchema };
