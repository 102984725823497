import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isArray from "lodash/isArray";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";

import { initialValues, validationSchema } from "./schema/schema";

const AddState = () => {
  const { post } = useFetch("/api/admin/city");
  const { get, response } = useFetch("/api/admin/state");

  useEffect(() => get(), []);

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    const { success, data, msg } = await post(val);

    if (success) {
      setSubmitting(false);
      push("/city");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="parameters" pageContent="add city" isBack={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="select state"
                    placeholder="Enter Country Name"
                    name="state"
                    value={values.state}
                    isInvalid={!!touched.state && !!errors.state}
                  >
                    {isArray(response?.data?.data) &&
                      response.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="Name"
                    placeholder="Enter city Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </WrapLayout>
  );
};

export default AddState;
