import * as Yup from "yup";
import "yup-phone-lite";

const initialValues = {
  name: "",
  img: "",
  instagram: "",
  mail: "",
  phone: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  img: Yup.string(),
  instagram: Yup.string(),
  mail: Yup.string().email(),
  phone: Yup.string().phone("IN"),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
