import { lazy, Suspense, useContext } from "react";

/// Components
// import { connect, useDispatch } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Index from "./jsx";
// action

import { AppContext } from "./scpl/context/AppProvider";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
/** */
import "./vendor/datatables/css/dataTables.min.css";
/** */
import "./css/style.css";
/** */
import { memo } from "react";
import "./css/custom.css";

const suspenseLoader = (
  <div className="lds-dual-container">
    <div class="lds-dual-ring"></div>
  </div>
);

const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => import("./jsx/pages/Login"));

function App(props) {
  const { isAuth } = useContext(AppContext);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
      <Redirect to="/login" />
    </Switch>
  );

  if (isAuth) {
    return (
      <>
        <Suspense
          fallback={
            suspenseLoader
            // <div id="preloader">
            //   <div className="sk-three-bounce">
            //     <div className="sk-child sk-bounce1"></div>
            //     <div className="sk-child sk-bounce2"></div>
            //     <div className="sk-child sk-bounce3"></div>
            //   </div>
            // </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            suspenseLoader
            // <div id="preloader">
            //   <div className="sk-three-bounce">
            //     <div className="sk-child sk-bounce1"></div>
            //     <div className="sk-child sk-bounce2"></div>
            //     <div className="sk-child sk-bounce3"></div>
            //   </div>
            // </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: isAuthenticated(state),
//   };
// };

export default withRouter(memo(App));
// export default withRouter(connect(mapStateToProps)(App));
