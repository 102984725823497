import useFetch from "use-http";

const useFileUpload = () => {
  const { post } = useFetch("/api/upload");

  const uploadImg = async (file) => {
    let img = "";

    const fd = new FormData();

    fd.append("file", file);
    const { data } = await post(fd);
    img = data;

    return img;
  };

  return { uploadImg };
};

export default useFileUpload;
