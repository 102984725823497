export const reducer = (state, action) => {
  switch (action.type) {
    case "auth":
      return {
        ...state,
        isAuth: true,
        authInfo: {
          user: action.user,
          email: action.email,
          authToken: action.authToken,
        },
      };

    case "logout":
      return {
        ...state,
        isAuth: false,
        authInfo: {
          user: "",
          email: "",
          authToken: "",
        },
      };

    default:
      return state;
  }
};
