export const MenuList = [
  {
    allRoutes: [
      "setup",
      "Admin User",
      "Admin Roles",
      "Email",
      "Credentials",
      "Templates",
      "Company Info",
    ],
    routeList: [
      {
        name: "setup",
        icon: "flaticon-025-dashboard",
        menu: [
          {
            name: "Admin User",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Admin Roles",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Email",
            allSubRoutes: ["Credentials", "Templates"],
            sub: [
              {
                name: "Credentials",
              },
              {
                name: "Templates",
              },
            ],
          },
          {
            name: "Company Info",
            allSubRoutes: [],
            sub: [],
          },
        ],
      },
    ],
  },
  {
    allRoutes: [
      "Parameters",
      "Gender",
      "Country",
      "State",
      "City",
      "Center",
      "School",
    ],
    routeList: [
      {
        name: "Parameters",
        icon: "flaticon-062-pencil",
        menu: [
          {
            name: "Gender",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Country",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "State",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "City",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Center",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "School",
            allSubRoutes: [],
            sub: [],
          },
        ],
      },
    ],
  },
  {
    allRoutes: [
      "Manage",
      "Banners",
      "Users",
      "Parents",
      "Addmissions",
      "Inquiry",
    ],
    routeList: [
      {
        name: "Manage",
        icon: "flaticon-022-copy",
        menu: [
          {
            name: "Banners",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Users",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Parents",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Addmissions",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Inquiry",
            allSubRoutes: [],
            sub: [],
          },
        ],
      },
    ],
  },
  {
    allRoutes: [
      "Boards",
      "Boards",
      "Standards",
      "Videos",
      "Subjects",
      "Chapters",
      "Boards Testimonials",
      "Faqs",
      "Courses",
      "Course Category",
      "Course Info",
      "Curriculum",
      "Chapter Group",
      "Exam Level",
      "Exam Mode",
      "Exam Fee Type",
    ],
    routeList: [
      {
        name: "Study",
        icon: "flaticon-065-pin",
        menu: [
          {
            name: "Boards",
            allSubRoutes: [
              "Boards",
              "Standards",
              "Videos",
              "Subjects",
              "Chapters",
              "Boards Testimonials",
              "Faqs",
              "Chapter Group",
            ],
            sub: [
              {
                name: "Boards",
              },
              {
                name: "Standards",
              },
              {
                name: "Subjects",
              },
              {
                name: "Chapter Group",
              },
              {
                name: "Chapters",
              },
              {
                name: "Boards Testimonials",
              },
              {
                name: "Faqs",
              },
              {
                name: "Videos",
              },
            ],
          },
          {
            name: "Courses",
            allSubRoutes: [
              "Courses",
              "Course Category",
              "Exam Level",
              "Exam Fee Type",
              "Course Info",
              "Curriculum",
              "Exam Mode",
              "Exam Fee Type",
            ],
            sub: [
              {
                name: "Courses",
              },
              {
                name: "Course Category",
              },
              {
                name: "Exam Level",
              },
              {
                name: "Exam Mode",
              },
              {
                name: "Exam FeeType",
              },
              {
                name: "Course Info",
              },
              {
                name: "Curriculum",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    allRoutes: [
      "Pages",
      "Cms",
      "Home",
      "Ranking Students",
      // "Home Cms",
      "Home Testimonials",
      "About Us",
      // "Classes",
      // "About Cms",
      "Faculty",
      "Success Story",
      "Rank",
      // "Success Story Cms",
      "About Testimonials",
      "Rank category",
    ],
    routeList: [
      {
        name: "Pages",
        icon: "flaticon-039-goal",
        menu: [
          {
            name: "Cms",
            allSubRoutes: [],
            sub: [],
          },
          {
            name: "Home",
            allSubRoutes: [
              "Ranking Students",
              // "Home Cms",
              "Home Testimonials",
            ],
            sub: [
              {
                name: "Ranking Students",
              },
              // {
              //   name: "Home Cms",
              // },
              {
                name: "Home Testimonials",
              },
            ],
          },
          {
            name: "About Us",
            allSubRoutes: [
              // "Classes",
              // "About Cms",
              "About Testimonials",
              "Faculty",
            ],
            sub: [
              // {
              //   name: "Classes",
              // },
              // {
              //   name: "About Cms",
              // },
              {
                name: "About Testimonials",
              },
              {
                name: "Faculty",
              },
            ],
          },
          {
            name: "Success Story",
            allSubRoutes: [
              "Rank",
              "Rank category",
              // "Success Story Cms"
            ],
            sub: [
              {
                name: "Rank category",
              },
              {
                name: "Rank",
              },
              // {
              //   name: "Success Story Cms",
              // },
            ],
          },
        ],
      },
    ],
  },
];
