import * as Yup from "yup";
import "yup-phone-lite";

const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const reggst = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

const initialValues = {
  name: "",
  information: "",
  img: "",
  establishmentYear: "",
  mobile: "",
  altMobile: "",
  email: "",
  altEmail: "",
  workInfo: "",
  gstNo: "",
  panNo: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  information: Yup.string(),
  img: Yup.string(),
  establishmentYear: Yup.string(),
  mobile: Yup.string().phone("IN"),
  altMobile: Yup.string().phone("IN"),
  email: Yup.string().email(),
  altEmail: Yup.string().email(),
  workInfo: Yup.string(),
  gstNo: Yup.string().matches(reggst),
  panNo: Yup.string().matches(regpan),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
