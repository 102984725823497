import capitalize from "lodash/capitalize";
import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";

const CustomInput = ({
  title,
  name,
  placeholder,
  isInvalid,
  errors,
  type,
  value,
  as,
}) => {
  return (
    <Form.Group className="form-group mb-3">
      <Form.Label>
        <strong>{capitalize(title)}</strong>
      </Form.Label>

      <Form.Control
        name={name}
        isInvalid={isInvalid}
        placeholder={capitalize(placeholder)}
        type={type ? type : "text"}
        as={as || "input"}
        value={value}
      />
      <Form.Control.Feedback type="isInvalid" className="text-danger fs-12">
        {capitalize(errors)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const CustomCheck = ({
  title,
  name,
  isInvalid,
  placeholder,
  errors,
  checked,
  setVal,
}) => {
  const handleChange = () => setVal(name, !!!checked);

  return (
    <Form.Group className="form-group mb-3">
      <Form.Label>
        <strong>{capitalize(title)}</strong>
      </Form.Label>

      <Form.Check
        name={name}
        isInvalid={isInvalid}
        label={capitalize(placeholder)}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <Form.Control.Feedback type="isInvalid" className="text-danger fs-12">
        {capitalize(errors)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const CustomSelect = ({
  title,
  name,
  placeholder,
  isInvalid,
  errors,
  type,
  value,
  children,
  onChange = null,
}) => {
  return (
    <Form.Group className="form-group mb-3">
      <Form.Label>
        <strong>{capitalize(title)}</strong>
      </Form.Label>

      <Form.Control
        name={name}
        isInvalid={isInvalid}
        placeholder={capitalize(placeholder)}
        type={type ? type : "text"}
        value={value}
        as="select"
        onChange={onChange && onChange}
      >
        <option value={""}>select</option>
        {children}
      </Form.Control>
      <Form.Control.Feedback type="isInvalid" className="text-danger fs-12">
        {capitalize(errors)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const CustomButton = ({ isLoading, bText }) => {
  return (
    <Button type="submit">
      {isLoading && (
        <Spinner
          style={{
            height: "1.5rem",
            width: "1.5rem",
            marginRight: "0.5rem",
          }}
          animation="border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {capitalize(bText)}
    </Button>
  );
};

export { CustomInput, CustomCheck, CustomButton, CustomSelect };
