import * as Yup from "yup";

const initialValues = {
  host: "",
  port: "",
  secure: false,
  user: "",
  password: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  host: Yup.string().required(),
  port: Yup.number().required(),
  secure: Yup.bool().oneOf([true, false]).required(),
  user: Yup.string().required(),
  password: Yup.string().required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
