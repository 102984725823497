import AboutTestimonials from "./scpl/pages/AboutTestimonials";
import AboutUsCms from "./scpl/pages/AboutUsCms";
import Banner from "./scpl/pages/Banner";
import Board from "./scpl/pages/Board";
import BoardFaq from "./scpl/pages/BoardFaq";
import BoardTestimonials from "./scpl/pages/BoardTestimonials";
import CategoryRanking from "./scpl/pages/CategoryRanking";
import Center from "./scpl/pages/Center";
import Chapters from "./scpl/pages/Chapters";
import City from "./scpl/pages/City";
// import ClassesCms from "./scpl/pages/ClassesCms";
import ChapterGroup from "./scpl/pages/ChapterGroup";
import Cms from "./scpl/pages/Cms";
import CompanyInfo from "./scpl/pages/CompanyInfo";
import Country from "./scpl/pages/Country";
import Course from "./scpl/pages/Course";
import CourseInfo from "./scpl/pages/CourseInfo";
import CouseCategory from "./scpl/pages/CouseCategory";
import EmailCredentials from "./scpl/pages/EmailCredentials";
import EmailTemplates from "./scpl/pages/EmailTemplates";
import ExamLevel from "./scpl/pages/ExamLevel";
import ExamMode from "./scpl/pages/ExamMode";
import Faculty from "./scpl/pages/Faculty";
import FeeType from "./scpl/pages/FeeType";
import FileUpload from "./scpl/pages/FileUpload";
import HomeCms from "./scpl/pages/HomeCms";
import HomeStudentRanking from "./scpl/pages/HomeStudentRanking";
import HomeTestimonials from "./scpl/pages/HomeTestimonials";
import School from "./scpl/pages/School";
import Standards from "./scpl/pages/Standards";
import State from "./scpl/pages/State";
import Subject from "./scpl/pages/Subject";
import SuccessRanking from "./scpl/pages/SuccessRanking";
import SuccessStoryCms from "./scpl/pages/SuccessStoryCms";

export const Routes = [
  { url: "center", component: Center },
  { url: "country", component: Country },
  { url: "state", component: State },
  { url: "city", component: City },
  { url: "banners", component: Banner },
  { url: "boards", component: Board },
  { url: "standards", component: Standards },
  { url: "subjects", component: Subject },
  { url: "boards-testimonials", component: BoardTestimonials },
  { url: "faqs", component: BoardFaq },
  { url: "courses", component: Course },
  { url: "course-category", component: CouseCategory },
  { url: "course-info", component: CourseInfo },
  { url: "credentials", component: EmailCredentials },
  { url: "templates", component: EmailTemplates },
  { url: "ranking-students", component: HomeStudentRanking },
  { url: "home-cms", component: HomeCms },
  { url: "home-testimonials", component: HomeTestimonials },
  { url: "about-testimonials", component: AboutTestimonials },
  { url: "faculty", component: Faculty },
  { url: "chapters", component: Chapters },
  { url: "about-cms", component: AboutUsCms },
  { url: "success-story-cms", component: SuccessStoryCms },
  { url: "school", component: School },
  { url: "rank-category", component: CategoryRanking },
  { url: "rank", component: SuccessRanking },
  { url: "company-info", component: CompanyInfo },
  { url: "file-upload", component: FileUpload },
  { url: "cms", component: Cms },
  { url: "admin-user", component: Cms },
  { url: "chapter-group", component: ChapterGroup },
  { url: "exam-level", component: ExamLevel },
  { url: "exam-mode", component: ExamMode },
  { url: "exam-fee-type", component: FeeType },
];
