import * as Yup from "yup";

const initialValues = {
  name: "",
  img: "",
  description: "",
  rank: "",
  percentage: "",
  showOrder: "",
  showLocation: "home",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  img: Yup.string(),
  description: Yup.string().required(),
  rank: Yup.number().required(),
  percentage: Yup.number().min(0).max(100).required(),
  showOrder: Yup.number().required(),
  showLocation: Yup.string().required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
