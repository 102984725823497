import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppDispatch } from "../context/AppProvider";

const Error500 = ({ error, resetErrorBoundary }) => {
  const dispatch = useContext(AppDispatch);

  const handleReset = () => {
    dispatch({ type: "logout" });
    resetErrorBoundary();
  };

  return (
    <div
      className="form-input-content text-center error-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
      }}
    >
      <h1 className="error-text font-weight-bold">500</h1>
      <h4>
        <i className="fa fa-times-circle text-danger" /> Internal Server Error
      </h4>
      <p>You do not have permission to view this resource</p>
      <div onClick={handleReset}>
        <Link className="btn btn-primary" to="/dashboard">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default Error500;
