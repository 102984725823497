import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";
import { initialValues, validationSchema } from "./schema";

const Update = () => {
  const { search } = useLocation();

  const { post, get, loading } = useFetch(
    `/api/admin/center/${parse(search).id}`
  );

  const { goBack } = useHistory();

  const [initState, setInitState] = useState(initialValues);

  useEffect(() => {
    (async () => {
      if (!isNil(search)) {
        const { data } = await get();

        setInitState({ ...data });
      }
    })();
  }, [search]);

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  const { uploadImg } = useFileUpload();

  const hnaldeSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);

    // let imgName = "";

    // if (!isNil(cropedImg)) {
    //   imgName = await uploadImg(cropedImg);

    //   // cogoToast.error("Please upload the respective image.");
    //   // return;
    // } else {
    //   imgName = val.img;
    // }

    const { success, msg } = await post({ ...val });

    if (success) {
      goBack();
    } else {
      cogoToast.error(msg);
    }
    setSubmitting(false);
  };

  if (loading === true) {
    return <></>;
  }

  return (
    <WrapLayout motherMenu="parameters" pageContent="Center">
      <Formik
        initialValues={initState}
        validationSchema={validationSchema}
        onSubmit={hnaldeSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          isSubmitting,
          setSubmitting,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomInput
                    name="name"
                    errors={errors.name}
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                    title="Center Name"
                    placeholder="Enter Center Name"
                  />
                </Col>
                <Col md="8">
                  <CustomInput
                    name="address"
                    errors={errors.address}
                    value={values.address}
                    isInvalid={!!touched.address && !!errors.address}
                    title="center address"
                    placeholder="Enter Center address"
                  />
                </Col>
                <Col md="4" className="d-none">
                  <Form.Group>
                    <Form.Label>
                      Center Image{" "}
                      <a
                        className="badge badge-primary"
                        href={`${process.env.REACT_APP_API_URL}/api/upload/${initState.img}`}
                        target="_blank"
                      >
                        old image
                      </a>
                    </Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomInput
                    name="email"
                    errors={errors.email}
                    value={values.email}
                    isInvalid={!!touched.email && !!errors.email}
                    title="center email"
                    placeholder="Enter Center email"
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    name="altEmail"
                    errors={errors.altEmail}
                    value={values.altEmail}
                    isInvalid={!!touched.altEmail && !!errors.altEmail}
                    title="center alternative email"
                    placeholder="Enter Center alternative email"
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    name="phone"
                    errors={errors.phone}
                    value={values.phone}
                    isInvalid={!!touched.phone && !!errors.phone}
                    title="center phone"
                    placeholder="Enter Center phone"
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    name="altPhone"
                    errors={errors.altPhone}
                    value={values.altPhone}
                    isInvalid={!!touched.altPhone && !!errors.altPhone}
                    title="center alternative phone"
                    placeholder="Enter Center alternative phone"
                  />
                </Col>
                <Col md="8" className="d-none">
                  <CustomInput
                    name="map"
                    errors={errors.map}
                    value={values.map}
                    isInvalid={!!touched.map && !!errors.map}
                    title="center map"
                    placeholder="Enter Center map"
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    checked={values.isActive}
                    errors={errors.isActive}
                    isInvalid={!!touched.isActive && !!errors.isActive}
                    name="isActive"
                    placeholder="make it active"
                    setVal={setFieldValue}
                    title="status"
                  />
                </Col>
                <Col md="8">
                  <CustomButton
                    type="submit"
                    bText="update"
                    isLoading={isSubmitting}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
      />
    </WrapLayout>
  );
};

export default Update;
