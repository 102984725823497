import * as Yup from "yup";

const initialValues = {
  question: "",
  answer: "",

  board: "",
  standard: "",
  subject: "Ku_jcJq9W",

  isActive: true,
};

const validationSchema = Yup.object().shape({
  question: Yup.string().required(),
  answer: Yup.string().required(),

  board: Yup.string().required(),
  standard: Yup.string().required(),
  subject: Yup.string().required(),

  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
