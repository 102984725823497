import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
//import SimpleReactLightbox from "simple-react-lightbox";
import { ErrorBoundary } from "react-error-boundary";
import ThemeContext from "./context/ThemeContext";
import { AppProvider } from "./scpl/context/AppProvider";
import Error500 from "./scpl/pages/Error500";

const handleReset = () => {
  try {
    /**
     * clearing local storage
     */
    window.localStorage.clear();

    /**
     * clearing coockies
     */

    ("use strict");

    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  } catch (err) {}
};

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <Provider store={store}>
        <BrowserRouter basename="/admin">
          <ThemeContext>
            <ErrorBoundary FallbackComponent={Error500} onReset={handleReset}>
              <App />
            </ErrorBoundary>
          </ThemeContext>
        </BrowserRouter>
      </Provider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
