import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";

import { initialValues, validationSchema } from "./schema/schema";

const AddCountry = () => {
  const { search } = useLocation();

  const { post, get } = useFetch(
    `/api/admin/chapter-group/${parse(search).id}`
  );

  const { get: getBoard, response: getBoardResponse } =
    useFetch("/api/admin/board");

  const { get: getStandard, response: getStandardResponse } = useFetch(
    "/api/admin/standard"
  );

  const { get: getSubject, response: getSubjectResponse } =
    useFetch("/api/admin/subject");

  const { push } = useHistory();

  const [initState, setInitState] = useState(initialValues);

  useEffect(() => {
    (async () => {
      if (!isNil(search)) {
        const { data } = await get();

        setInitState({
          ...data,
          subject: data.subject._id,
          board: data.board._id,
          standard: data.standard._id,
        });
      }
    })();
  }, [search]);

  useEffect(() => {
    (async () => {
      await Promise.all([getBoard(), getStandard(), getSubject()]);
    })();
  }, []);

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    const { success, data, msg } = await post(val);

    if (success) {
      setSubmitting(false);
      push("/chapter-group");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout
      motherMenu="parameters"
      pageContent="update chapter group"
      isBack={true}
    >
      <Formik
        initialValues={initState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="board"
                    placeholder="select board"
                    errors={errors.board}
                    name="board"
                    isInvalid={!!touched.board && !!errors.board}
                    value={values.board}
                  >
                    {Array.isArray(getBoardResponse?.data?.data) &&
                      getBoardResponse.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return <option value={x._id}>{x.name}</option>;
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="standard"
                    placeholder="select standard"
                    errors={errors.standard}
                    name="standard"
                    isInvalid={!!touched.standard && !!errors.standard}
                    value={values.standard}
                  >
                    {Array.isArray(getStandardResponse?.data?.data) &&
                      getStandardResponse.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => x.board._id === values.board)
                        .map((x) => {
                          return <option value={x._id}>{x.name}</option>;
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="subject"
                    placeholder="select subject"
                    errors={errors.subject}
                    name="subject"
                    isInvalid={!!touched.subject && !!errors.subject}
                    value={values.subject}
                  >
                    {Array.isArray(getSubjectResponse?.data?.data) &&
                      getSubjectResponse.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => x.standard._id === values.standard)
                        .map((x) => {
                          return <option value={x._id}>{x.name}</option>;
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="Name"
                    placeholder="Enter Country Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="update" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </WrapLayout>
  );
};

export default AddCountry;
