import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isArray from "lodash/isArray";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";
import { initialValues, validationSchema } from "./schema/schema";

const AddTestimonial = () => {
  const { get, response } = useFetch("/api/admin/board");
  const { get: getStandard, response: resStandard } = useFetch(
    "/api/admin/standard"
  );
  const { get: getSubject, response: resSubject } =
    useFetch("/api/admin/subject");

  const { post } = useFetch("/api/admin/boards-faq");

  useEffect(() => {
    (async () => {
      await Promise.all([get(), getStandard(), getSubject()]);
    })();
  }, []);

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);

    // if (isNil(cropedImg)) {
    //   cogoToast.error("Please upload the respective image.");
    //   return;
    // }

    // const img = await uploadImg(cropedImg);

    const { success, data, msg } = await post({ ...val });

    if (success) {
      setSubmitting(false);
      push("/faqs");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="boards" pageContent="add faq" isBack={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="select board"
                    placeholder="Enter Country Name"
                    name="board"
                    value={values.board}
                    isInvalid={!!touched.board && !!errors.board}
                  >
                    {isArray(response?.data?.data) &&
                      response.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="select standard"
                    placeholder="Enter Country Name"
                    name="standard"
                    value={values.standard}
                    isInvalid={!!touched.standard && !!errors.standard}
                  >
                    {isArray(resStandard?.data?.data) &&
                      resStandard.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => {
                          console.log(x.board);
                          if (x.board._id === values.board) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4" className="d-none">
                  <CustomSelect
                    title="select subject"
                    placeholder="Enter Country Name"
                    name="subject"
                    value={values.subject}
                    isInvalid={!!touched.subject && !!errors.subject}
                  >
                    {isArray(resSubject?.data?.data) &&
                      resSubject.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => {
                          if (x.standard._id === values.standard) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>

                <Col md="12">
                  <CustomInput
                    title="Question"
                    placeholder="Enter Question"
                    name="question"
                    value={values.question}
                    isInvalid={!!touched.question && !!errors.question}
                  />
                </Col>

                <Col md="12">
                  <CustomInput
                    title="Answer"
                    placeholder="Enter Answer"
                    name="answer"
                    value={values.answer}
                    isInvalid={!!touched.answer && !!errors.answer}
                    as="textarea"
                  />
                </Col>

                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </WrapLayout>
  );
};

export default AddTestimonial;
