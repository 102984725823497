import * as Yup from "yup";

const initialValues = {
  name: "",
  isActive: true,
  duration: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  duration: Yup.number().required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
