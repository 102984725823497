import cogoToast from "cogo-toast";
import { Formik } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";
import { initialValues, validationSchema } from "./schema";

const Add = () => {
  const { post } = useFetch("/api/admin/center");
  const { goBack } = useHistory();

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  const { uploadImg } = useFileUpload();

  const hnaldeSubmit = async (val, { setSubmitting }) => {
    // if (isNil(cropedImg)) {
    //   cogoToast.error("Please upload the respective image.");
    //   return;
    // }

    setSubmitting(true);
    // const img = await uploadImg(cropedImg);

    const { success, msg } = await post({ ...val });

    if (success) {
      goBack();
    } else {
      cogoToast.error(msg);
    }
    setSubmitting(false);
  };

  return (
    <WrapLayout motherMenu="parameters" pageContent="Center" isBack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={hnaldeSubmit}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          isSubmitting,
          setSubmitting,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomInput
                    name="name"
                    errors={errors.name}
                    values={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                    title="Center Name"
                    placeholder="Enter Center Name"
                  />
                </Col>
                <Col md="8">
                  <CustomInput
                    name="address"
                    errors={errors.address}
                    values={values.address}
                    isInvalid={!!touched.address && !!errors.address}
                    title="center address"
                    placeholder="Enter Center address"
                  />
                </Col>
                <Col md="4" className="d-none">
                  <Form.Group>
                    <Form.Label> Center Image</Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomInput
                    name="email"
                    errors={errors.email}
                    values={values.email}
                    isInvalid={!!touched.email && !!errors.email}
                    title="center email"
                    placeholder="Enter Center email"
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    name="altEmail"
                    errors={errors.altEmail}
                    values={values.altEmail}
                    isInvalid={!!touched.altEmail && !!errors.altEmail}
                    title="center alternative email"
                    placeholder="Enter Center alternative email"
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    name="phone"
                    errors={errors.phone}
                    values={values.phone}
                    isInvalid={!!touched.phone && !!errors.phone}
                    title="center phone"
                    placeholder="Enter Center phone"
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    name="altPhone"
                    errors={errors.altPhone}
                    values={values.altPhone}
                    isInvalid={!!touched.altPhone && !!errors.altPhone}
                    title="center alternative phone"
                    placeholder="Enter Center alternative phone"
                  />
                </Col>
                <Col md="8" className="d-none">
                  <CustomInput
                    name="map"
                    errors={errors.map}
                    values={values.map}
                    isInvalid={!!touched.map && !!errors.map}
                    title="center map"
                    placeholder="Enter Center map"
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    checked={values.isActive}
                    errors={errors.isActive}
                    isInvalid={!!touched.isActive && !!errors.isActive}
                    name="isActive"
                    placeholder="make it active"
                    setVal={setFieldValue}
                    title="status"
                  />
                </Col>
                <Col md="8">
                  <CustomButton
                    type="submit"
                    bText="add"
                    isLoading={isSubmitting}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
      />
    </WrapLayout>
  );
};

export default Add;
