import * as Yup from "yup";

const initialValues = {
  board: "",
  name: "",
  price: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  board: Yup.string().required(),
  name: Yup.string().required(),
  price: Yup.number().min(1).required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
