import capitalize from "lodash/capitalize";
import React from "react";
import { Card } from "react-bootstrap";
import PageTitle from "../../../../jsx/layouts/PageTitle";

const WrapLayout = ({
  children,
  motherMenu,
  pageContent,
  shouldCard = true,
  isBack,
}) => {
  return (
    <>
      <PageTitle
        motherMenu={capitalize(motherMenu)}
        pageContent={capitalize(pageContent)}
        activeMenu={capitalize(pageContent)}
        isBack={isBack}
      />
      {shouldCard ? (
        <Card>
          <Card.Body>{children}</Card.Body>
        </Card>
      ) : (
        children
      )}
    </>
  );
};

export default WrapLayout;
