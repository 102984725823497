import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";

import { initialValues, validationSchema } from "./schema/schema";

const AddState = () => {
  const { post } = useFetch("/api/admin/success-ranking");
  const { get, response } = useFetch("/api/admin/ranking-category");
  const { get: getSchool, response: schoolResponse } =
    useFetch("/api/admin/school");
  const { get: getSubject, response: subjectResponse } =
    useFetch("/api/admin/subject");

  const { uploadImg } = useFileUpload();

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  useEffect(() => {
    (async () => {
      await Promise.all([get(), getSchool(), getSubject()]);
    })();
  }, []);

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    if (isNil(cropedImg)) {
      cogoToast.error("Please upload the respective image.");
      return;
    }

    setSubmitting(true);

    const img = await uploadImg(cropedImg);

    const { success, data, msg } = await post({ ...val, img });

    if (success) {
      setSubmitting(false);
      push("/rank");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="pages" pageContent="add rank" isBack={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="Select Ranking Category"
                    placeholder="Enter Country Name"
                    name="category"
                    value={values.category}
                    isInvalid={!!touched.category && !!errors.category}
                  >
                    {isArray(response?.data?.data) &&
                      response.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="student name"
                    placeholder="Enter student Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>

                <Col md="4">
                  <Form.Group>
                    <Form.Label> Student Image</Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>

                <Col md="4" className="d-none">
                  <CustomInput
                    title="description"
                    placeholder="Enter student description"
                    name="description"
                    value={values.description}
                    isInvalid={!!touched.description && !!errors.description}
                  />
                </Col>

                <Col md="4">
                  <CustomInput
                    title="percentage"
                    placeholder="Enter student percentage"
                    name="percentage"
                    value={values.percentage}
                    isInvalid={!!touched.percentage && !!errors.percentage}
                  />
                </Col>

                <Col md="4">
                  <CustomInput
                    title="show Order"
                    placeholder="Enter student show Order"
                    name="showOrder"
                    value={values.showOrder}
                    isInvalid={!!touched.showOrder && !!errors.showOrder}
                  />
                </Col>

                <Col md="4">
                  <CustomSelect
                    title="select school"
                    placeholder="Enter student show Order"
                    name="school"
                    value={values.school}
                    isInvalid={!!touched.school && !!errors.school}
                  >
                    {schoolResponse?.data?.data
                      .filter((x) => x.isActive === true)
                      .map((x) => {
                        return (
                          <option value={x._id} key={x._id}>
                            {x.name}
                          </option>
                        );
                      })}
                  </CustomSelect>
                </Col>

                <Col md="4">
                  <CustomSelect
                    title="select subject"
                    placeholder="Enter student show Order"
                    name="subject"
                    value={values.subject}
                    isInvalid={!!touched.subject && !!errors.subject}
                  >
                    {subjectResponse?.data?.data
                      .filter((x) => x.isActive === true)
                      .map((x) => {
                        return (
                          <option value={x._id} key={x._id}>
                            {x.board.name} - {x.standard.name} - {x.name}
                          </option>
                        );
                      })}
                  </CustomSelect>
                </Col>

                <Col md="4">
                  <CustomCheck
                    title="is Image only"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isImageOnly}
                    name="isImageOnly"
                    isInvalid={!!touched.isImageOnly && !!errors.isImageOnly}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>

      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
        aspect={300 / 351}
      />
    </WrapLayout>
  );
};

export default AddState;
