import React from 'react';



const Home = () => {
	return(
		<>
			<h2>Blank Page</h2>
		</>
	)
}
export default Home;