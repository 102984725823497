import { parse } from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";

export const ManageLayout = ({ Index, Add, Update }) => {
  const { search } = useLocation();

  if (parse(search).action === "add") {
    return <Add />;
  } else if (parse(search).action === "update") {
    return <Update />;
  } else {
    return <Index />;
  }
};
