import * as Yup from "yup";

const initialValues = {
  // board: "",
  // standard: "",
  // subject: "",
  name: "",
  personInfo: "",
  personDesignation: "",
  img: "",
  rating: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  // board: Yup.string().required(),
  // standard: Yup.string().required(),
  // subject: Yup.string().required(),
  name: Yup.string().required(),
  personInfo: Yup.string().required(),
  personDesignation: Yup.string().required(),
  img: Yup.string(),
  rating: Yup.number().min(1).max(5).required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
