import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";
import { initialValues, validationSchema } from "./schema/schema";

const UpdateTestimonial = () => {
  const { search } = useLocation();
  // const { get, response } = useFetch("/api/admin/board");
  // const { get: getStandard, response: resStandard } = useFetch(
  //   "/api/admin/standard"
  // );
  // const { get: getSubject, response: resSubject } =
  //   useFetch("/api/admin/subject");

  const { get: getBoardTestimonail, post } = useFetch(
    `/api/admin/about-testimonial/${parse(search).id}`
  );

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  const { uploadImg } = useFileUpload();

  // useEffect(() => {
  //   (async () => {
  //     await Promise.all([get(), getStandard(), getSubject()]);
  //   })();
  // }, []);

  const [initState, setInitState] = useState(initialValues);

  useEffect(() => {
    (async () => {
      if (!isNil(search)) {
        const { data } = await getBoardTestimonail();

        setInitState({
          ...data,
          // board: data.board._id,
          // subject: data.subject._id,
          // standard: data.standard._id,
        });
      }
    })();
  }, [search]);

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);

    let imgName = "";

    // if (isNil(cropedImg)) {
    //   cogoToast.error("Please upload the respective image.");
    //   return;
    // }

    if (!isNil(cropedImg)) {
      imgName = await uploadImg(cropedImg);
      // cogoToast.error("Please upload the respective image.");
      // return;
    } else {
      imgName = val.img;
    }
    // const img = await uploadImg(cropedImg);

    const { success, data, msg } = await post({ ...val, img: imgName });

    if (success) {
      setSubmitting(false);
      push("/about-testimonials");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout
      motherMenu="pages"
      pageContent="update testimonials"
      isBack={true}
    >
      <Formik
        initialValues={initState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                {/* <Col md="4">
                  <CustomSelect
                    title="select board"
                    placeholder="Enter Country Name"
                    name="board"
                    value={values.board}
                    isInvalid={!!touched.board && !!errors.board}
                  >
                    {isArray(response?.data?.data) &&
                      response.data.data.map((x) => {
                        return (
                          <option value={x._id} key={x._id}>
                            {x.name}
                          </option>
                        );
                      })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="select standard"
                    placeholder="Enter Country Name"
                    name="standard"
                    value={values.standard}
                    isInvalid={!!touched.standard && !!errors.standard}
                  >
                    {isArray(resStandard?.data?.data) &&
                      resStandard.data.data
                        .filter((x) => {
                          console.log(x.board);
                          if (x.board._id === values.board) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="select subject"
                    placeholder="Enter Country Name"
                    name="subject"
                    value={values.subject}
                    isInvalid={!!touched.subject && !!errors.subject}
                  >
                    {isArray(resSubject?.data?.data) &&
                      resSubject.data.data
                        .filter((x) => {
                          if (x.standard._id === values.standard) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col> */}
                <Col md="12">
                  <CustomInput
                    title="description"
                    placeholder="Enter description"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                    as="textarea"
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="person Name"
                    placeholder="Enter person Name"
                    name="personInfo"
                    value={values.personInfo}
                    isInvalid={!!touched.personInfo && !!errors.personInfo}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="person designation"
                    placeholder="Enter person designation"
                    name="personDesignation"
                    value={values.personDesignation}
                    isInvalid={
                      !!touched.personDesignation && !!errors.personDesignation
                    }
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="person rating"
                    placeholder="Enter person rating"
                    name="rating"
                    value={values.rating}
                    isInvalid={!!touched.rating && !!errors.rating}
                  />
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>
                      {" "}
                      Person Image
                      {"  "}{" "}
                      <a
                        className="badge badge-primary"
                        href={`${process.env.REACT_APP_API_URL}/api/upload/${initState.img}`}
                        target="_blank"
                      >
                        old image
                      </a>
                    </Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="update" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
        aspect={1}
      />
    </WrapLayout>
  );
};

export default UpdateTestimonial;
