/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import urlSlug from "url-slug";
import { MenuList } from "./sidebarList";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {MenuList.map((x) => {
            return x.routeList.map((xMain) => {
              return (
                <li
                  className={`${
                    x.allRoutes
                      .map((x) => urlSlug(lowerCase(`/${x}`)))
                      .includes(path)
                      ? "mm-active"
                      : ""
                  }`}
                >
                  <Link className="has-arrow ai-icon" to="#">
                    <i className={xMain.icon}></i>
                    <span className="nav-text">{capitalize(xMain.name)}</span>
                  </Link>
                  <ul>
                    {xMain.menu.map((xSub) => {
                      const isSub = xSub.sub.length === 0 ? true : false;

                      return (
                        <li
                          className={
                            !isSub &&
                            `${
                              xSub.allSubRoutes
                                .map((x) => {
                                  return urlSlug(lowerCase(x));
                                })
                                .includes(path)
                                ? "mm-active"
                                : ""
                            }`
                          }
                        >
                          <Link
                            className={
                              isSub
                                ? `${
                                    path === urlSlug(lowerCase(`/${xSub.name}`))
                                      ? "mm-active"
                                      : ""
                                  }`
                                : "has-arrow"
                            }
                            to={
                              isSub ? urlSlug(lowerCase(`/${xSub.name}`)) : "#"
                            }
                          >
                            {xSub.name}
                          </Link>

                          {!isSub && (
                            <ul
                              className={
                                xSub.allSubRoutes
                                  .map((x) => {
                                    return urlSlug(lowerCase(x));
                                  })
                                  .includes(path)
                                  ? "mm-show"
                                  : ""
                              }
                            >
                              {xSub.sub.map((xExtraSub) => {
                                let isShow = false;

                                x.allRoutes.map((x) => {
                                  if (
                                    urlSlug(lowerCase(xExtraSub.name)) === path
                                  ) {
                                    isShow = true;
                                  }
                                  return 0;
                                });

                                return (
                                  <li>
                                    <Link
                                      className={isShow ? "mm-active" : ""}
                                      to={urlSlug(
                                        lowerCase(`/${xExtraSub.name}`)
                                      )}
                                    >
                                      {xExtraSub.name}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            });
          })}
        </MM>
        <div className="copyright d-none">
          <p>
            <strong>Innap Hotel Admin</strong> © 2021 All Rights Reserved
          </p>
          <p className="fs-12">
            Made with <span className="heart"></span> by DexignZone
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
