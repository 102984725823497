import cogoToast from "cogo-toast";
import { decode, encode } from "js-base64";
import React, { createContext, Suspense, useEffect, useReducer } from "react";
import { CachePolicies, Provider } from "use-http";
import { reducer } from "../reducer/AppReeducer";
import capitalize from "lodash/capitalize";

const AppContext = createContext();
const AppDispatch = createContext();

const stateVersion = 0;
const initialState = {
  isAuth: false,
  authInfo: {
    user: "",
    email: "",
    authToken: "",
  },
};

const getState = () => {
  try {
    return (
      JSON.parse(
        decode(window.localStorage.getItem(`__id_${stateVersion}`)) || null
      ) || initialState
    );
  } catch (err) {
    return initialState;
  }
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getState());

  useEffect(() => {
    window.localStorage.setItem(
      `__id_${stateVersion}`,
      encode(JSON.stringify(state))
    );

    console.log(state);
  }, [state]);

  return (
    <Provider
      url={process.env.REACT_APP_API_URL}
      options={{
        suspense: true,
        retries: 3,
        headers: {
          Authorization: state?.authInfo?.authToken || "",
        },
        interceptors: {
          response: ({ response }) => {
            if (response.status === 401) {
              cogoToast.error(
                capitalize("We can not authorise you. Please relogin.")
              );
            }
            return response;
          },
        },
        cachePolicy: CachePolicies.NO_CACHE,
      }}
    >
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <AppContext.Provider value={state}>
          <AppDispatch.Provider value={dispatch}>
            {children}
          </AppDispatch.Provider>
        </AppContext.Provider>
      </Suspense>
    </Provider>
  );
};

export { AppProvider, AppContext, AppDispatch };
