import { Editor } from "@tinymce/tinymce-react";
import React from "react";

const CustomEditor = ({ name, initialValues, setVal }) => {
  const handleEditorChange = (e) => setVal(name, e);

  return (
    <Editor
      onEditorChange={handleEditorChange}
      value={initialValues}
      init={{
        height: 500,
        menubar: true,
        menu: {
          file: {
            title: "File",
            items: "newdocument restoredraft | preview | print ",
          },
          edit: {
            title: "Edit",
            items: "undo redo | cut copy paste | selectall | searchreplace",
          },
          view: {
            title: "View",
            items:
              "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen",
          },
          insert: {
            title: "Insert",
            items:
              "image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime",
          },
          format: {
            title: "Format",
            items:
              "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
          },
          tools: {
            title: "Tools",
            items: "spellchecker spellcheckerlanguage | code wordcount",
          },
          table: {
            title: "Table",
            items: "inserttable | cell row column | tableprops deletetable",
          },
          help: { title: "Help", items: "help" },
        },
        plugins: [
          "advlist autolink lists link image code charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
          "bullist numlist outdent indent | removeformat | help ",
        content_style: "body { color: #828282 }",
        skin: "oxide-dark",
        content_css: "dark",
      }}
    />
  );
};

export { CustomEditor };
