import * as Yup from "yup";

const initialValues = {
  name: "",
  img: "",
  description: "",
  rank: "",
  percentage: "",
  showOrder: "",
  school: "",
  category: "",
  subject: "",
  isActive: true,
  isImageOnly: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  img: Yup.string(),
  description: Yup.string(),
  rank: Yup.string(),
  // percentage: Yup.number().min(0).max(100).required(),
  percentage: Yup.number().min(0).max(100),
  showOrder: Yup.number(),
  school: Yup.string(),
  category: Yup.string(),
  subject: Yup.string(),
  isImageOnly: Yup.bool().oneOf([true, false]).required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
