import isNil from "lodash/isNil";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const TO_RADIANS = Math.PI / 180;

const ImageCrop = ({
  isShow,
  img,
  setImg,
  toggleIsShow,
  aspect,
  isFlexible,
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale] = useState(1);
  const [rotate] = useState(0);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleCrop = () => {
    if (!isNil(document.getElementById("imgRef"))) {
      const file = dataURLtoBlob(
        document.getElementById("imgRef").toDataURL("img/png")
      );

      setImg(file);
      toggleIsShow();
    }
  };

  return (
    <>
      <Modal show={isShow} onHide={toggleIsShow} backdrop="static">
        <Modal.Header>
          <Modal.Title>Img Crop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isNil(img) && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={isFlexible ? null : aspect || 1}
            >
              <img className="img-fluid" ref={imgRef} src={img} />
            </ReactCrop>
          )}

          <div className="d-none">
            {!isNil(completedCrop) && (
              <canvas id="imgRef" ref={previewCanvasRef} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleIsShow}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCrop}>
            Crop Image
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";
  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;
  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;
  ctx.save();

  ctx.translate(-cropX, -cropY);

  ctx.translate(centerX, centerY);

  ctx.rotate(rotateRads);

  ctx.scale(scale, scale);

  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );
  ctx.restore();
}

let previewUrl = "";
function toBlob(canvas) {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
}

async function imgPreview(image, crop, scale = 1, rotate = 0) {
  const canvas = document.createElement("canvas");
  canvasPreview(image, canvas, crop, scale, rotate);
  const blob = await toBlob(canvas);
  if (previewUrl) {
    URL.revokeObjectURL(previewUrl);
  }
  previewUrl = URL.createObjectURL(blob);
  return previewUrl;
}

function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);
    return () => {
      clearTimeout(t);
    };
  }, deps);
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], {
    type: mime,
  });
}

export default ImageCrop;
