import * as Yup from "yup";

const initialValues = {
  name: "",
  credential: "",
  template: "<p>This is the initial content of the editor</p>",
  uniqId: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  credential: Yup.string().required(),
  template: Yup.string().required(),
  uniqId: Yup.string(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
