import * as Yup from "yup";

const initialValues = {
  board: "",
  standard: "",
  subject: "",
  name: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  board: Yup.string().required(),
  standard: Yup.string().required(),
  subject: Yup.string().required(),
  name: Yup.string().required(),
  isActive: Yup.bool().oneOf([true, false]).required(),
});

export { initialValues, validationSchema };
