import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import { default as React } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";

import { initialValues, validationSchema } from "./schema/schema";

const AddCountry = () => {
  const { post } = useFetch("/api/admin/student-ranking");

  const { uploadImg } = useFileUpload();

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    if (isNil(cropedImg)) {
      cogoToast.error("Please upload the respective image.");
      return;
    }

    const img = await uploadImg(cropedImg);

    const { success, data, msg } = await post({ ...val, img });

    if (success) {
      setSubmitting(false);
      push("/ranking-students");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout
      motherMenu="Pages"
      pageContent="add student ranking"
      isBack={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomInput
                    title="student Name"
                    placeholder="Enter student Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label> Student Image</Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="rank location"
                    placeholder="ex. vadodara, all india"
                    name="description"
                    value={values.description}
                    isInvalid={!!touched.description && !!errors.description}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="student percentage"
                    placeholder="Enter student percentage"
                    name="percentage"
                    value={values.percentage}
                    isInvalid={!!touched.percentage && !!errors.percentage}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="student rank"
                    placeholder="Enter student rank"
                    name="rank"
                    value={values.rank}
                    isInvalid={!!touched.rank && !!errors.rank}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="student show Order"
                    placeholder="Enter student show Order"
                    name="showOrder"
                    value={values.showOrder}
                    isInvalid={!!touched.showOrder && !!errors.showOrder}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
        aspect={1}
      />
    </WrapLayout>
  );
};

export default AddCountry;
