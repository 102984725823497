import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isArray from "lodash/isArray";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";
import { initialValues, validationSchema } from "./schema/schema";

const AddStandard = () => {
  const { get, response } = useFetch("/api/admin/board");
  const { get: getStandard, response: resStandard } = useFetch(
    "/api/admin/standard"
  );
  const { get: getSubject, response: resSubject } =
    useFetch("/api/admin/subject");

  const { post } = useFetch("/api/admin/subject");

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  const { uploadImg } = useFileUpload();

  useEffect(() => {
    (async () => {
      await Promise.all([get(), getStandard()]);
    })();
  }, []);

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);

    // if (isNil(cropedImg)) {
    //   cogoToast.error("Please upload the respective image.");
    //   return;
    // }

    // const img = await uploadImg(cropedImg);

    const { success, data, msg } = await post({ ...val, img: "" });

    if (success) {
      setSubmitting(false);
      push("/subjects");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="boards" pageContent="add subject" isBack={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomSelect
                    title="select board"
                    placeholder="Enter Country Name"
                    name="board"
                    value={values.board}
                    isInvalid={!!touched.board && !!errors.board}
                  >
                    {isArray(response?.data?.data) &&
                      response.data.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomSelect
                    title="select standard"
                    placeholder="Enter Country Name"
                    name="standard"
                    value={values.standard}
                    isInvalid={!!touched.standard && !!errors.standard}
                  >
                    {isArray(resStandard?.data?.data) &&
                      resStandard.data.data
                        .filter((x) => x.isActive === true)
                        .filter((x) => {
                          if (x.board._id === values.board) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map((x) => {
                          return (
                            <option value={x._id} key={x._id}>
                              {x.name}
                            </option>
                          );
                        })}
                  </CustomSelect>
                </Col>
                <Col md="4">
                  <CustomInput
                    title="subject Name"
                    placeholder="Enter subject Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label> Center Image</Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
        aspect={1920 / 600}
      />
    </WrapLayout>
  );
};

export default AddStandard;
