import cogoToast from "cogo-toast";
import { Formik } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
} from "../../components/Form/input";

import { initialValues, validationSchema } from "./schema/schema";

const AddCountry = () => {
  const { post } = useFetch("/api/admin/exam-fee-type");

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    const { success, data, msg } = await post(val);

    if (success) {
      setSubmitting(false);
      push("/exam-fee-type");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout
      motherMenu="parameters"
      pageContent="add fee type"
      isBack={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomInput
                    title="Name"
                    placeholder="Enter Fee Type Name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="duration (month)"
                    placeholder="Enter Exam duration"
                    name="duration"
                    value={values.duration}
                    isInvalid={!!touched.duration && !!errors.duration}
                  />
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </WrapLayout>
  );
};

export default AddCountry;
