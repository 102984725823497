import cogoToast from "cogo-toast";
import { compress } from "image-conversion";
import { useState } from "react";
import { useBoolean } from "react-use";

const fileTypes = ["image/png", "image/jpeg", "image/jpg"];

const useHandleImg = () => {
  const [img, setImg] = useState(null);
  const [cropedImg, setCropedImg] = useState(null);
  const [showModal, toggleShowModal] = useBoolean(false);

  const handleFileChange = async (e) => {
    if (!fileTypes.includes(e.target.files[0].type)) {
      cogoToast.error("Please upload a valid image file.");
      return;
    }

    const unCompressedImage = e.target.files[0];

    const compressedImage = await compress(unCompressedImage, {
      quality: 0.8,
      type: "image/jpeg",
      width: 1600,
    });

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImg(reader.result.toString() || "");
      toggleShowModal();
    });
    reader.readAsDataURL(compressedImage);
  };

  return {
    img,
    cropedImg,
    handleFileChange,
    setCropedImg,
    showModal,
    toggleShowModal,
  };
};

export { useHandleImg };
