import cogoToast from "cogo-toast";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import {
  CustomButton,
  CustomInput,
  CustomSelect,
} from "../../components/Form/input";
import useFileUpload from "../../components/hooks/useFileUpload";
import { useHandleImg } from "../../components/hooks/useHandleImg";
import ImageCrop from "../../components/ImageCrop";

import useForceUpdate from "use-force-update";

import { initialValues, validationSchema } from "./schema/schema";

const AddBanner = () => {
  const [isNewState, setIsNewState] = useState(false);

  const forceUpdate = useForceUpdate();

  const { get, post } = useFetch("/api/admin/company-info");
  const { push } = useHistory();

  const { uploadImg } = useFileUpload();

  const [initState, setInitState] = useState(initialValues);

  const {
    handleFileChange,
    showModal,
    img,
    setCropedImg,
    toggleShowModal,
    cropedImg,
  } = useHandleImg();

  useEffect(() => {
    (async () => {
      const { data } = await get();

      if (data === null) {
        setIsNewState(false);
      } else {
        setIsNewState(true);
        setInitState(data);
      }
    })();
  }, []);

  const handleSubmit = async (val, { setSubmitting }) => {
    if (isNil(initState.img) && isNil(cropedImg)) {
      cogoToast.error("Please upload the respective image.");
      return;
    }

    setSubmitting(true);

    if (isNewState) {
      let imgName = "";

      if (!isNil(cropedImg)) {
        imgName = await uploadImg(cropedImg);
      } else {
        imgName = val.img;
      }

      const { success, data, msg } = await post({ ...val, img: imgName });

      if (success) {
        setSubmitting(false);
        cogoToast.success("company info updated successfully");

        forceUpdate();
      } else {
        cogoToast.error("something went wrong on server");
      }
    } else {
      const img = await uploadImg(cropedImg);
      const { success, data, msg } = await post({ ...val, img });

      if (success) {
        setSubmitting(false);
        cogoToast.success("company info updated successfully");

        forceUpdate();
      } else {
        cogoToast.error("something went wrong on server");
      }
    }
  };

  return (
    <WrapLayout motherMenu="manage" pageContent="add company info">
      <Formik
        initialValues={initState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomInput
                    title="company name"
                    placeholder="Enter company name"
                    name="name"
                    value={values.name}
                    isInvalid={!!touched.name && !!errors.name}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="company working hours"
                    placeholder="Enter company working hours"
                    name="workInfo"
                    value={values.workInfo}
                    isInvalid={!!touched.workInfo && !!errors.workInfo}
                  />
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>
                      {" "}
                      company Image
                      {initState?.img && (
                        <>
                          {" "}
                          {"  "}{" "}
                          <a
                            className="badge badge-primary"
                            href={`${process.env.REACT_APP_API_URL}/api/upload/${initState.img}`}
                            target="_blank"
                          >
                            old image
                          </a>
                        </>
                      )}
                    </Form.Label>
                    <Form.File
                      accept="image/png,image/jpeg"
                      name="img"
                      isInvalid={!!touched.img && !!errors.img}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>

                <Col md="12">
                  <CustomInput
                    title="company description"
                    placeholder="Enter company description"
                    name="information"
                    value={values.information}
                    as="textarea"
                    isInvalid={!!touched.information && !!errors.information}
                  />
                </Col>

                <Col md="4">
                  <CustomSelect
                    title="company establishment Year"
                    placeholder="Enter company establishment Year"
                    name="establishmentYear"
                    value={values.establishmentYear}
                    isInvalid={
                      !!touched.establishmentYear && !!errors.establishmentYear
                    }
                  >
                    {Array(100)
                      .fill(0)
                      .map((x, i) => (
                        <option key={1954 + i} value={1954 + i}>
                          {1954 + i}
                        </option>
                      ))}
                  </CustomSelect>
                </Col>

                <Col md="4">
                  <CustomInput
                    title="company email"
                    placeholder="Enter company email"
                    name="email"
                    value={values.email}
                    isInvalid={!!touched.email && !!errors.email}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="company alternative email"
                    placeholder="Enter company alternative email"
                    name="altEmail"
                    value={values.altEmail}
                    isInvalid={!!touched.altEmail && !!errors.altEmail}
                  />
                </Col>

                <Col md="4">
                  <CustomInput
                    title="company phone"
                    placeholder="Enter company phone"
                    name="mobile"
                    value={values.mobile}
                    isInvalid={!!touched.mobile && !!errors.mobile}
                  />
                </Col>

                <Col md="4">
                  <CustomInput
                    title="company alternative phone"
                    placeholder="Enter company alternative phone"
                    name="altMobile"
                    value={values.altMobile}
                    isInvalid={!!touched.altMobile && !!errors.altMobile}
                  />
                </Col>

                <Col md="4">
                  <CustomInput
                    title="company pan No"
                    placeholder="Enter company pan No"
                    name="panNo"
                    value={values.panNo}
                    isInvalid={!!touched.panNo && !!errors.panNo}
                  />
                </Col>

                <Col md="4">
                  <CustomInput
                    title="company gst No"
                    placeholder="Enter company gstNo"
                    name="gstNo"
                    value={values.gstNo}
                    isInvalid={!!touched.gstNo && !!errors.gstNo}
                  />
                </Col>

                {/* <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col> */}
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add / Update" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ImageCrop
        isShow={showModal}
        toggleIsShow={toggleShowModal}
        img={img}
        setImg={setCropedImg}
        //  aspect={16 / 8.08}
        isFlexible={true}
      />
    </WrapLayout>
  );
};

export default AddBanner;
