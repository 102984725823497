import * as Yup from "yup";

var regExp =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

const initialValues = {
  board: "",
  standard: "",
  subject: "",
  name: "",
  pdf: "",
  video: "",
  order: "",
  isActive: true,
  chapterGroup: "",
};

const validationSchema = Yup.object().shape({
  board: Yup.string().required(),
  standard: Yup.string().required(),
  subject: Yup.string().required(),
  name: Yup.string().required(),
  pdf: Yup.string(),
  order: Yup.number().required(),
  video: Yup.string().matches(regExp),
  isActive: Yup.bool().oneOf([true, false]).required(),
  chapterGroup: Yup.string(),
});

export { initialValues, validationSchema };
