import * as Yup from "yup";
import "yup-phone-lite";

export const initialValues = {
  name: "",
  address: "",
  //   description: "",
  img: "",
  map: "",
  email: "",
  altEmail: "",
  phone: "",
  altPhone: "",
  isActive: true,
};
export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  address: Yup.string().required(),
  //   description: Yup.string().required(),
  img: Yup.string(),
  map: Yup.string(),
  email: Yup.string().email().required(),
  altEmail: Yup.string(),
  phone: Yup.string().phone("IN").required(),
  altPhone: Yup.string().phone("IN"),
  isActive: Yup.bool().oneOf([true, false]).required(),
});
