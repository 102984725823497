import cogoToast from "cogo-toast";
import { Formik } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import WrapLayout from "../../components/Form/common/WrapLayout";
import { CustomEditor } from "../../components/Form/Editor";
import {
  CustomButton,
  CustomCheck,
  CustomInput,
} from "../../components/Form/input";
import { initialValues, validationSchema } from "./schema/schema";

const AddGender = () => {
  const { post } = useFetch("/api/admin/cms");

  const { push } = useHistory();

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    const { success, data, msg } = await post(val);

    if (success) {
      setSubmitting(false);
      push("/home-cms");
    } else {
      cogoToast.error("something went wrong on server");
    }
  };

  return (
    <WrapLayout motherMenu="Pages" pageContent="add cms" isBack={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Row>
                <Col md="4">
                  <CustomInput
                    title="label"
                    placeholder="Enter label"
                    name="label"
                    value={values.label}
                    isInvalid={!!touched.label && !!errors.label}
                  />
                </Col>
                <Col md="4">
                  <CustomInput
                    title="title"
                    placeholder="Enter title"
                    name="title"
                    value={values.title}
                    isInvalid={!!touched.title && !!errors.title}
                  />
                </Col>
                <Col md="12">
                  <Form.Group>
                    <Form.Label>Page Content</Form.Label>
                    <CustomEditor
                      initialValues={values.content}
                      name="content"
                      setVal={setFieldValue}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <CustomCheck
                    title="mark as active"
                    placeholder="Make Active"
                    setVal={setFieldValue}
                    checked={values.isActive}
                    name="isActive"
                    isInvalid={!!touched.isActive && !!errors.isActive}
                  />
                </Col>
                <Col md="12"></Col>
                <Col md="4">
                  <CustomButton bText="Add" isLoading={isSubmitting} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </WrapLayout>
  );
};

export default AddGender;
